import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Danez = () => {
  return (
    <Layout>
      <Head title="Danez Smith" />
      <h3>Danez Smith</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        St. Paul, MN.
      </h4>
      <p>
        <OutboundLink href="http://www.danezsmithpoet.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://twitter.com/Danez_Smif">
          Twitter
        </OutboundLink>
      </p>

      <img
        src="https://www.portlandlibrary.com/wp-content/uploads/2020/06/DanezSmithpoem-1024x1024.jpg"
        alt="danez smith poem"
      />

      <hr />

      <div className="poemContainer">
        <h3>I'm Going Back to Minnesota Where Sadness Makes Sense </h3>
        <p style={{ marginBottom: 0 }}>
          O California, don't you know the sun is only a god
        </p>
        <p>if you learn to starve for him? I'm bored with the ocean</p>

        <p style={{ marginBottom: 0 }}>
          I stood at the lip of it, dressed in down, praying for snow
        </p>
        <p>I know, I'm strange, too much light makes me nervous</p>

        <p style={{ marginBottom: 0 }}>
          at least in this land where the trees always bear green.
        </p>
        <p>I know something that doesn't die can't be beautiful.</p>

        <p style={{ marginBottom: 0 }}>
          Have you ever stood on a frozen lake, California?
        </p>
        <p>The sun above you, the snow & stalled sea—a field of mirror</p>

        <p style={{ marginBottom: 0 }}>
          all demanding to be the sun too, everything around you
        </p>
        <p>is light & it's gorgeous & if you stay too long it will kill you</p>

        <p style={{ marginBottom: 0 }}>
          & it's so sad, you know? You're the only warm thing for miles
        </p>
        <p>& the only thing that can't shine.</p>
      </div>
      <hr />
      <img
        src="https://homologylit.files.wordpress.com/2019/03/poems-for-dujie-2-e1553279325302.jpg?w=1100"
        alt="danez smith poem"
      />
      <hr />
      <img
        src="https://i.pinimg.com/736x/d4/41/e9/d441e95ec825dc01430bef995daea805.jpg"
        alt="danez smith poem"
      />
      <hr />
      <div className="poemContainerNoLineBreaks">
        <h3>broke n rice</h3>

        <p>wit h bee f a nd veg gi es</p>

        <p>b less ed wi th an e gg</p>

        <p>sa ff ro n sul li ed c hil lin</p>

        <p>w ith g arl ic or d irty n ot qu ite</p>

        <p>re d bu ggin the bea ns</p>

        <p>or jus t ri ce, wat er th e mi ra cl e</p>

        <p>of sa lt t he gr a in s pr omi se</p>

        <p>to pil lo w an d st retc h</p>

        <p>i u sed to ha te r i ce</p>

        <p>hat ed it h ated h ow</p>

        <p>br oke it sou nde d</p>

        <p>rice rice rice a po cket</p>

        <p>w ith thr ee co p per co ins</p>

        <p>hu n ger s tamb our ine</p>

        <p>i h ate d al l of it</p>

        <p>h ated the w ate r</p>

        <p>gh ostbl eac hed by sta rc h</p>

        <p>hat ed th e p uff y mo on s</p>

        <p>po ckin g my sto m a ch</p>

        <p>lik e a si ck ne ss</p>

        <p>end in g sic kn ess</p>

        <p>hat ed ev eryth ing</p>

        <p>th at i woul d mar ry no w</p>

        <p>l eg it wo uld</p>

        <p>i wo uld m arry wa ter</p>

        <p>coul d it hav e me</p>

        <p>wi tho ut de adi ng me</p>

        <p>i d m arry the m oon</p>

        <p>cha nge my n ame</p>

        <p>to it s ho ur</p>

        <p>i wou ld w ed t he y o lk</p>

        <p>go ld r ice stu ck</p>

        <p>in th e yo lk yell ow tee th</p>

        <p>of m y hom e girls w ere it n ot</p>

        <p>alr eady jew els</p>

        <p>mini ng th eir lau ghs</p>

        <p>bl in ge d ou t li ke a do w ry</p>

        <p>shi nin</p>

        <p>nex t to th e bi lls of m int</p>

        <p>bov ine rib bons</p>

        <p>co n fet t ied c ar rots</p>
      </div>
    </Layout>
  );
};

export default Danez;
